import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/neat.css'
import 'codemirror/theme/neo.css'
import 'codemirror/mode/javascript/javascript.js'
import 'codemirror/mode/htmlmixed/htmlmixed.js'
import './styles.css'

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Controlled as CodeMirror } from 'react-codemirror2'
import withStyles from '@material-ui/core/styles/withStyles'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Chip from '@material-ui/core/Chip'
import Fade from '@material-ui/core/Fade'
import Popover from '@material-ui/core/Popover'

const styles = ({ palette }) => ({
  root: {},
  chip: {
    color: palette.accent500.contrastText,
    backgroundColor: palette.accent500.main,
    '&:hover': {
      color: palette.accent400.contrastText,
      backgroundColor: palette.accent400.main,
    },
  },
  codeContainer: {
    width: '100%',
    minWidth: 600,
    minHeight: 200,
    maxWidth: '200vw',
  },
})

class CustomScripts extends Component {
  state = {}

  render() {
    let { classes, value = '', helperText, disabled, required, label, settings } = this.props
    const { anchorEl } = this.state
    const open = Boolean(anchorEl)

    return (
      <FormControl fullWidth required={required}>
        <InputLabel style={{ position: 'inherit' }} shrink>
          {label}
        </InputLabel>

        <Chip
          label={settings.chipLabel || '⚡️ JS SCRIPT'}
          className={classes.chip}
          onClick={this._onClick}
          color="primary"
        />

        <Popover
          TransitionComponent={Fade}
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <CodeMirror
            value={value}
            editorDidMount={editor => {
              editor.focus()
            }}
            className={classes.codeContainer}
            options={{
              theme: 'neo',
              mode: settings.mode || 'javascript',
              lineNumbers: true,
              readOnly: disabled,
              autoScroll: true,
            }}
            onBeforeChange={(...args) => {
              this._handleEditorChange(...args)
            }}
          />
        </Popover>

        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }

  _handleEditorChange = (editor, data, value) => {
    const { onUpdate } = this.props
    onUpdate && onUpdate(value)
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
    const { name, value, onChange } = this.props
    onChange &&
      onChange({
        target: {
          name,
          value,
        },
      })
  }

  _onClick = e => {
    this.setState({ anchorEl: e.currentTarget })
  }
}

CustomScripts.propTypes = {
  helperText: PropTypes.string,
}

export default withStyles(styles)(CustomScripts)
